<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div v-if="$screen.width <= 567">
				<MobileViewsLocationList
					:locations="locations"
					@main-location:show-image="showPictures"
					@main-location:delete="handleMainLocationDelete"
					@main-location:edit="handleEditLocation"
					@main-location:add="initAndOpenNewLocation"
					@main-location:change-option="handleChangeShowOptionSelected"
				/>
			</div>
			<div v-else>
				<b-row class="form">
					<b-col md="12">
						<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
							{{ FormMSG(2, 'Locations list') }}
						</h1>
					</b-col>
					<b-col cols="12" xl="12">
						<transition name="slide">
							<b-card no-body class="card-border-blue-light">
								<b-card-body>
									<b-row class="d-flex align-items-center">
										<b-col :cols="`${$screen.width >= 992 ? 4 : 0}`" class="my-1">
											<b-input-group v-if="$screen.width >= 992">
												<b-form-input v-model="filter" type="text" :placeholder="FormMSG(9, 'Type to Search')" />
												<b-input-group-append class="cursor-pointer">
													<b-input-group-text class="btn-search">
														<component
															:is="getLucideIcon('Search')"
															color="#FFFFFF"
															:size="16"
															class="icon"
															:stroke-width="2.5"
															v-if="filter.length === 0"
														/>
														<component
															:is="getLucideIcon('X')"
															color="#FFFFFF"
															:size="16"
															class="icon"
															:stroke-width="2.5"
															@click="filter = ''"
															v-else
														/>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-col>
										<b-col :cols="`${$screen.width >= 992 ? 8 : 12}`">
											<b-row class="d-flex align-items-center">
												<b-col cols="8" class="d-flex align-items-center">
													<div class="fs-14 fw-700 mr-3">
														{{ FormMSG(147, 'Show') }}
													</div>
													<div style="padding-top: 15px">
														<b-form-group v-slot="{ ariaDescribedby }">
															<b-form-radio-group
																id="showOptionLocation"
																v-model="showOptionSelected"
																:options="showOptions"
																:aria-describedby="ariaDescribedby"
																name="radio-options"
																plain
																@change="handleChangeShowOptionSelected"
															/>
														</b-form-group>
													</div>
												</b-col>
												<b-col cols="4">
													<b-button variant="primary" class="float-right pl-3 pr-3" @click="initAndOpenNewLocation">
														<component :is="getLucideIcon('Plus')" :size="16" />
														{{ getAddLabel }}
													</b-button>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
									<b-row class="mt-1">
										<b-col cols="12" xl="12">
											<div v-if="$screen.width >= 992" class="location-list-container">
												<b-table
													:selected-variant="selectedColor"
													:hover="hover"
													selectable
													:select-mode="selectMode"
													responsive="sm"
													:items="locations"
													style="text-align: left"
													:fields="showForGreen ? fieldsForGreen : fields"
													:filter="filter"
													sticky-header="800px"
													bordered
													striped
													small
													:head-variant="hv"
													:empty-text="FormMSG(98, 'No locations found')"
													show-empty
													@row-clicked="handleEditLocation"
												>
													<template #head(actionSelected)="">
														<b-form-checkbox
															v-if="locations.length > 0"
															v-model="selectAll"
															size="lg"
															@change="handleInputSelectAll"
														/>
													</template>
													<template #cell(actionSelected)="data">
														<b-form-checkbox
															v-model="data.item.checked"
															size="lg"
															@change="handleInputItemChecked($event, data.item)"
														/>
													</template>
													<template #cell(view)="data">
														<div v-if="data.item.images.length">
															<component :is="getLucideIcon('Image')" :size="20" color="#B8BEC5" />
															<button class="btn-transparent" @click="showPictures(data.item.images)">
																<component :is="getLucideIcon('File')" :size="22" color="#47C7BF" />
															</button>
														</div>
													</template>
													<template #cell(generatorType)="{ index }">
														<div @click.stop="handleClickStop">
															<b-form-select
																:value="0"
																:options="fuelTypeOptions"
																@change="handleChangeGeneratorFuelType($event, index)"
															/>
														</div>
													</template>
												</b-table>
											</div>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-button
												size="sm"
												variant="custom-outline-gray"
												class="pl-5 pr-5"
												:disabled="locationsIdToDelete.length === 0"
												@click="handleDeleteLocations"
											>
												{{ FormMSG(154, 'Delete') }}
											</b-button>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</transition>
					</b-col>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { store } from '@/store';
import { isNil } from '@/shared/utils';
import { getFileExtension } from '@/shared/helpers';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import MobileViewsLocationList from '@/components/MobileViews/Location/Main';
import * as icons from 'lucide-vue';
import moment from 'moment';
import { addressFormatter } from '@/shared/fomaters';
import { deleteLocations } from '@/cruds/locations.crud';
import _ from 'lodash';
import { ACTION } from '@/shared/constants';

export default {
	name: 'LocationPage',
	components: { MobileViewsLocationList },
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			hv: 'dark',
			erreur: {},
			warning: '',
			locationPageType: 'add-edit',
			selectMode: 'single',
			selectedColor: 'primary',
			isFormOpen: false,
			filter: '',

			selectAll: false,
			changeCheckedFromSelectAll: true,

			showOptionSelected: -1,
			locationsIdToDelete: [],
			locations: [],

			changeAllFromSelectAll: true,
			valueSelectAllLocation: false
		};
	},
	computed: {
		...mapGetters({
			currLocation: 'locations/currLocation',
			projectLocationsList: 'locations/projectLocationsList'
		}),

		showForGreen() {
			return this.$route.name === 'greenLocations';
		},

		showOptions() {
			return [
				{ text: this.FormMSG(148, 'All'), value: -1 },
				{ text: this.FormMSG(175, 'Confirmed'), value: 0 },
				{ text: this.FormMSG(169, 'Potential'), value: 1 }
			];
		},
		getAddLabel() {
			return store.isPme() ? this.FormMSG(132, 'Add Office') : this.FormMSG(1, 'Add Location');
		},
		fields() {
			return [
				{
					key: 'actionSelected',
					label: '',
					class: 'text-center actionSelected',
					sortable: false
				},
				{
					key: 'no',
					label: this.FormMSG(168, 'No.'),
          sortable: true,
          sortByFormatted: true,
					formatter: (value, key, item) => {
						return item.id;
					}
				},
				{
					key: 'setName',
					label: this.FormMSG(126, 'Name'),
					sortable: true
				},
				{
					key: 'mainCharacteristic',
					label: this.FormMSG(127, 'Main characteristic'),
					sortable: true,
					formatter: (value, key, item) => {
						return value;
					}
				},
				{
					key: 'address',
					label: this.FormMSG(128, 'Address'),
					sortable: true,
          sortByFormatted: true,
					formatter: (value, key, item) => {
						return addressFormatter(item.address5);
					}
				},
				{
					key: 'city',
					label: this.FormMSG(129, 'City'),
					sortable: true,
          sortByFormatted: true,
					formatter: (value, key, item) => {
						return item.address5.country;
					}
				},
				{
					key: 'type',
					label: this.FormMSG(130, 'Type'),
					sortable: true,
					formatter: (value, key, item) => {
						return this.formatLocationType(item);
					}
				},
				{
					key: 'status',
					label: this.FormMSG(135, 'Status'),
					sortable: true,
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(1358, value);
					}
				},
				{
					key: 'getInDate',
					label: this.FormMSG(136, 'Start date'),
					formatter: (v) => {
						return moment(new Date(v)).format('DD/MM/YYYY');
					},
					sortable: true
				},
				{
					key: 'getOutDate',
					label: this.FormMSG(133, 'End date'),
					formatter: (v) => {
						return moment(new Date(v)).format('DD/MM/YYYY');
					},
					sortable: true
				},
				{
					key: 'view',
					label: this.FormMSG(134, 'View'),
					sortable: false,
					class: 'text-center'
				}
			];
		},
		fieldsForGreen() {
			return [
				{
					key: 'actionSelected',
					label: '',
					class: 'text-center actionSelected',
					sortable: false,
					tdClass: 'pt-2'
				},
				{
					key: 'no',
					label: this.FormMSG(168, 'No.'),
					formatter: (value, key, item) => {
						return item.id;
					},
					sortable: true,
					tdClass: 'pt-3'
				},
				{
					key: 'setName',
					label: this.FormMSG(126, 'Name'),
					sortable: true,
					tdClass: 'pt-3'
				},
				{
					key: 'city',
					label: this.FormMSG(369, 'Country'),
					sortable: true,
					formatter: (value, key, item) => {
						return item.address5.country;
					},
					tdClass: 'pt-3'
				},
				{
					key: 'type1',
					label: this.FormMSG(375, 'Type 1'),
					sortable: true,
					formatter: (value, key, item) => {
						// clickup : https://app.clickup.com/t/86bx7nbkf (nuemro 7)
						return this.formatLocationType(item);
					},
					tdClass: 'pt-3'
				},
				{
					// clickup : https://app.clickup.com/t/86bx7nbkf (nuemro 6)
					key: 'filmingArea',
					label: this.FormMSG(425, 'Area'),
					sortable: true,
					formatter: (value) => {
						return value + ' ' + this.FormMSG(379, 'm2');
					},
					class: 'text-right',
					tdClass: 'pt-3'
				},
				{
					key: 'totNbOfDaysOccupation',
					label: this.FormMSG(426, 'Days used'),
					sortable: true,
					class: 'text-right',
					tdClass: 'pt-3'
				},
				{
					key: 'consumptionElectricityTotal',
					label: this.FormMSG(435, 'Electricity'),
					sortable: true,
					formatter: (value) => {
						return value + ' ' + this.FormMSG(436, 'kWh');
					},
					class: 'text-right',
					tdClass: 'pt-3'
				},
				// {
				// 	key: 'generatorType',
				// 	label: this.FormMSG(481, 'Generator type'),
				// 	sortable: false
				// },
				// {
				// 	key: 'consumption',
				// 	label: this.FormMSG(482, 'Consumption'),
				// 	sortable: true,
				// 	formatter: (value, key, item) => {
				// 		if (_.isNil(item.generatorFuelType)) {
				// 			return item.volumeTotalDieselUsed + ' ' + this.FormMSG(1265, 'L');
				// 		}
				// 		if (item.generatorFuelType === 0) {
				// 			return item.volumeTotalDieselUsed + ' ' + this.FormMSG(1265, 'L');
				// 		} else if (item.generatorFuelType === 1) {
				// 			return item.volumeTotalGasUsed + ' ' + this.FormMSG(1621, 'm3');
				// 		} else if (item.generatorFuelType === 2) {
				// 			return item.volumeTotalGasolineUlUsed + ' ' + this.FormMSG(1265, 'L');
				// 		} else if (item.generatorFuelType === 3) {
				// 			return item.volumeTotalHydrogenUsed + ' ' + this.FormMSG(1622, 'kg');
				// 		}
				// 	},
				// 	class: 'text-right',
				// 	tdClass: 'pt-3'
				// },
				{
					key: 'getInDate',
					label: this.FormMSG(136, 'Start date'),
					formatter: (v) => {
						return moment(new Date(v)).format('DD/MM/YYYY');
					},
					sortable: true,
					tdClass: 'pt-3'
				},
				{
					key: 'getOutDate',
					label: this.FormMSG(133, 'End date'),
					formatter: (v) => {
						return moment(new Date(v)).format('DD/MM/YYYY');
					},
					sortable: true,
					tdClass: 'pt-3'
				}
			];
		},
		fuelTypeOptions() {
			return this.FormMenu(1402);
		}
	},

	watch: {
		projectLocationsList: {
			handler(newVal) {
				this.locations = [];
				this.locationsIdToDelete = [];
				if (newVal.length > 0) {
					this.locations = newVal.map((option) => ({
						...option,
						checked: false
					}));
				}
			},
			immediate: true
		}
	},

	async created() {
		await this.getProjectLocationList({
			Status: -1,
			RemoveLocationDecorFilm: false,
			RemoveLocationPME: true,
			RemovePremise: true
		});
	},
	methods: {
		...mapActions({
			setNewLocation: 'locations/setNewLocation',
			getProjectLocationList: 'locations/getProjectLocationList',
			deleteLocation: 'locations/deleteLocation',
			//deleteLocations: 'locations/deleteLocations',
			setCurrentLocation: 'locations/setCurrentLocation',
			destroyCurrentLocation: 'locations/destroyCurrentLocation',
			setDetailsForGreenManager: 'locations/setDetailsForGreenManager'
		}),

		formatLocationType(item) {
			let format = this.GetTextFromMenuNumberAndMenuValue(1356, item.type1) + ' - ';
			if (item.type !== 0) format = format + this.GetTextFromMenuNumberAndMenuValue(1026, item.type);
			return format;
		},

		handleChangeGeneratorFuelType(payload, index) {
			this.locations[index].generatorFuelType = payload;
		},

		handleClickStop() {},

		async handleMainLocationDelete(payload) {
			const action = async () => {
				await this.deleteLocation(payload.id);
			};

			this.confirmModal(action, this.FormMSG(451, 'Are you sure ?'));
		},

		async handleChangeShowOptionSelected(payload) {
			await this.getProjectLocationList({
				Status: payload,
				RemoveLocationDecorFilm: false,
				RemoveLocationPME: true,
				RemovePremise: true
			});
		},

		handleInputItemChecked(payload, location) {
			const findIndex = this.locationsIdToDelete.findIndex((option) => option === parseInt(location.id, 10));

			if (findIndex > -1) {
				this.locationsIdToDelete.splice(findIndex, 1);

				if (this.locationsIdToDelete.length === 0) {
					this.selectAll = false;
				}
			} else if (findIndex === -1) {
				this.locationsIdToDelete.push(parseInt(location.id, 10));
				this.selectAll = true;
			}
		},

		handleInputSelectAll(value) {
			this.locationsIdToDelete = [];
			this.locations = this.locations.map((option) => {
				if (value) {
					this.locationsIdToDelete.push(parseInt(option.id, 10));
				}
				return {
					...option,
					checked: value
				};
			});
		},

		async handleDeleteLocations() {
			const action = async () => {
				deleteLocations(this.locationsIdToDelete);
				await this.getProjectLocationList({
					Status: -1,
					RemoveLocationDecorFilm: false,
					RemoveLocationPME: true,
					RemovePremise: true
				});
			};

			this.confirmModal(action, this.FormMSG(58, 'Are you sure ?'));
		},

		getTitle() {
			return store.isPme() ? this.FormMSG(131, 'Office locations') : this.FormMSG(111, 'Locations');
		},
		/**
		 * @param {Array} images
		 */
		showPictures(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				options: {
					presentationMode: 'doc',
					hideCropButton: true,
					hideDeleteButton: true
				}
			});
		},
		/**
		 * @param {Object} location
		 */
		async handleEditLocation(location) {
			await this.setCurrentLocation(location);
			this.goToForm(ACTION.UPDATE);
		},
		/**
		 * @param {Number} id - purchaseOrderID
		 */
		handleDeleteLocation(id) {
			if (isNil(id)) return;

			const txt = this.$_t(55554, 'Please confirm that you want delete this location');

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.$_t(55553, 'Please Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.$_t(55551, 'YES'),
					cancelTitle: this.$_t(55552, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then((s) => {
					if (!s) return;
					this.destroyCurrentLocation();
					const list = this.projectLocationsList;
					const index = list.findIndex((l) => l.id === id);
					if (~index) list.splice(index, 1);
					this.deleteLocation(id);
				})
				.catch((err) => console.log(err));
		},
		async initAndOpenNewLocation() {
			// if (!this.isFormOpen) await this.setNewLocation();
			// this.isFormOpen = !this.isFormOpen;
			await this.setNewLocation();
			this.goToForm();
		},
		goToForm(action = ACTION.ADD) {
			if (isNil(this.currLocation)) return;
			const { id } = this.currLocation;

			if (this.showForGreen) {
				this.setDetailsForGreenManager(true);
			} else {
				this.setDetailsForGreenManager(false);
			}

			this.$router.push({ path: `/locations/${action.toLowerCase()}/${id}` });
		},
		handleSubmitLocation() {
			this.isFormOpen = false;
		},
		/**
		 * @param {String} label
		 * @param {Number or null} id
		 * @return {String}
		 */
		$_t(id, label) {
			return this.FormMSG(id, label);
		}
		// getLucideIcon(name) {
		// 	return icons[name]
		// }
	}
};
</script>
